.PopUp .popup_profile{
    width: 500px;
}
.PopUp .button_profile{
    width: -webkit-fill-available;
}
.PopUp .align_profile{
    text-align: left;
}
.PopUp .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .pass{
    width: 120px;
}
