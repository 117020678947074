html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
}
#root {
    width: 100%;
    height: 100%;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.name_project{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}
.page_released {
    display: flex;
    width: 220px;
    gap: 6px;
    background-color: #ffffff;
    padding: 6px 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.space_div{
    width: 24px;
}
p{
    padding: 0px;
    margin: 0;
}
.space_block{
    margin-top: 16px;
}
.list_opt_alt_page{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgb(0 187 209);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(0 187 209);
    border-radius: 6px;
}
/* end */


/* svg icons with fixed size */
svg {
    /* width: 24px;
    height: 24px; */
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
}
th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    background-color: #E2E8F0;
    padding: 16px;
}
tr{
    background-color: #ffffff;
}
tr:nth-child(2n){
    background-color: #F6FAFF;
}
td{
    padding: 16px;
}
th:nth-child(-n + 1){
    border-top-left-radius: 16px;
}
th:nth-last-child(-n + 1){
    border-top-right-radius: 16px;
}
tr:nth-last-child(-n + 1) td:nth-child(-n + 1){
    border-bottom-left-radius: 16px;
}
tr:nth-last-child(-n + 1) td:nth-last-child(-n + 1){
    border-bottom-right-radius: 16px;
}
.div_data_project {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    width: -webkit-fill-available;
    flex-wrap: wrap;
}
/* end */


/* img */
.show_img_{
    display: flex;
}
.show_img_ .icons{
    width: 30px;
    height: 30px;
}
.icons_menu{
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
}
/* end */


/* input file */
.new_file_add{
    background-color: #c9b174;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
}
.new_file_add:hover, .new_file_add_active{
    background-color: #996e00;
}
.new_file_add label{
    cursor: pointer;
}
.new_file_add input[type="file"]{
    display: none;
}
/* end */


/* box text */
.jodit-react-container{
    width: -webkit-fill-available;
    text-align: left;
}
.jodit-wysiwyg p{
    padding: 0 !important;
    margin: 0 !important;
    font-family: "inter";
}
.jodit-status-bar{
    display: none;
}
.jodit-add-new-line, .jodit-add-new-line_after, .jodit-dialog__header-toolbar{
    display: none !important;
}
.jodit-dialog__panel{
    /* height: 200px !important;
    max-height: 200px !important;
    min-height: 200px !important; */
    width: 380px !important;
}
.jodit-ui-button_keep, .jodit-ui-button_insert_as_text, .jodit-ui-button_text-icons_true, .jodit-status-bar{
    display: none !important;
}
.jodit-toolbar__box{
    background-color: #EEF1F5 !important;
    border-bottom: none !important;
}
.jodit-container:not(.jodit_inline){
    border-color: #EEF1F5 !important;
}
.jodit-ui-button_insert_only_text{
    background-color: #7c9f06 !important;
    color: #FFFFFF !important;
    display: block !important;
}
.jodit-ui-button_insert_only_text > span{
    display: none !important;
}
/* .jodit-dialog__header-title::after{
    content: "Copiar texto";
} */
.jodit-ui-button_insert_only_text::after{
    content: "Copiar somente o texto!";
}
.jodit-dialog__content{
    display: none !important;
}
.jodit-dialog_footer_true .jodit-dialog__footer{
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt{
    width: -webkit-fill-available;
    text-align: center;
    max-width: 100% !important;
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt > label{
    display: none !important;
}
.jodit-wysiwyg p img{
    vertical-align: middle;
}
.type_free{
    background-color: #9eb7d3;
    padding: 6px 10px;
    width: 60px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.type_free_active{
    background-color: #324d6b;
}
/* end */


/* Select seatch */
.css-13cymwt-control{
    border: 2px solid #ebebeb !important;
    font-family: 'Inter' !important;
    padding: 2px;
}
.css-1nmdiq5-menu{
    z-index: 999 !important;
}
.css-1fdsijx-ValueContainer{
    padding: 0px 8px;
}
.css-1hb7zxy-IndicatorsContainer{
    max-height: 32px!important;
}
.css-1xc3v61-indicatorContainer{
    padding: 0px;
}
/* end */


/* return */
.div_return {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-transform: uppercase;
}
.div_button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.button_save{
    background-color: #7c9f06;
    width: max-content;
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 6px;
}
.icon_return{
    width: 30px;
    height: 30px;
}
/* end */


/* input search */
.new_block {
    /* height: 44px;
    min-height: 44px;
    max-height: 44px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}
.select_date{
    width: max-content;
}
.div_topic{
    position: relative;
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
}
.input_search {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    /* height: 46px; */
}
.div_input{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.search {
    border: none;
    border-radius: 6px;
    font-size: 16px;
    padding: 6px 6px 6px 30px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #eef0f5;
    width: 140px;
    border-radius: 20px;
    outline: none;
    border: 2px solid #CDD1D6;
}
.input_search ::placeholder{
    color: #606875;
    font-weight: 500;
    font-size: 14px;
}
.icons_search{
    position: absolute;
    height: 16px;
    top: 10px;
    left: 10px;
    width: 16px;
    transition: 0.5s;
}
.span_search_input{
    position: absolute;
    left: 40px;
    transition: 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
.search:valid ~ .span_search_input, .search:focus ~ .span_search_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
}
.search:valid, .search:focus {
    border: 2px solid #CDD1D6;
}
/* end */


/* list pages */
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}
.numb_page{
    background-color: #111827;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.numb_page:hover{
    background-color: #4a566e;
}
.page_active {
    background-color: #a9b9cb;
}
/* end */


/* div show or new data */
.div_show_or_new_data{
    margin-top: 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 6px;
}
.div_show_or_new_data .title_block{
    font-size: 20px;
    font-weight: 500;
}
.div_show_or_new_data .add_project {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #cdd1d6;
    border-radius: 8px;
}
.div_show_or_new_data .add_data {
    display: flex;
}
.div_show_or_new_data .show_data_add {
    flex-direction: column;
    padding: 20px 10px 10px;
    background-color: #ffffff;
    border-radius: 6px;
    border: 2px solid #ebebeb;
}
.div_show_or_new_data .div_data_project {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    width: -webkit-fill-available;
}
.div_show_or_new_data .space_show_data{
    padding: 0px;
    margin-top: 8px;
}
.div_show_or_new_data .list_opt_add{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    gap: 20px !important;
}
/* end */


/* input fixed */
input, select, textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.span_name_input{
    position: absolute;
    left: 16px;
    transition: 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    font-weight: 500;
    font-style: italic;
}
input:valid ~ .span_name_input, input:focus ~ .span_name_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
    left: 6px;
}
input:valid, input:focus{
    border: 2px solid #EDEDED;
}
.span_search_select{
    position: absolute;
    left: 8px;
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
    left: 6px;
}
.span_name_select{
    position: absolute;
    left: 8px;
    top: -10px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #eef0f5;
    padding: 0 10px;
}
.div_select{
    border: 2px solid #cdd1d6;
    border-radius: 6px;
    font-size: 14px;
    padding: 6px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #eef0f5;
}
.div_select .span_name_input, .div_select .span_name_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #eef0f5;
    padding: 0 10px;
    left: 6px;
}
.input_text {
    background-color: #eef0f5;
}
.input_new_presentation{
    border-radius: 6px;
    resize: none;
}
.space_data{
    flex-grow: 1;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 80%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register_data{
    background-color: #7c9f06;
    width: auto;
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 16px;
}
.yes_update {
    background-color: #76c57e;
}
.not_update {
    background-color: #e95656;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
/* end */


.show_data_register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.list_opt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.icons_, .icon, .icons, .icons_project, .opt_icon_list{
    width: 24px;
    height: 24px;
    display: block;
}
.lock {
    width: 50px;
    height: 50px;
    display: block;
}
.icon_link{
    width: 32px;
    height: 32px;
    display: block;
}


/* select */
.search_year{
    width: 70px;
    text-align: center;
    cursor: pointer;
}
.search_month, .search_status{
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.click_show_search{
    position: relative;
}
.click_show_search_selected{
    position: absolute;
    width: 124px;
    margin-top: 4px;
    background-color: #FFFFFF;
    transition: opacity 247ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 165ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    overflow: hidden auto;
    border-radius: 6px;
    max-height: 200px;
    cursor: pointer;
}
.click_show_search_selected_year{
    width: 86px;
}
.opt_list_search{
    padding: 6px 12px;
    text-align: left;
}
.opt_list_search:hover{
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.icon_select_close{
    width: 10px;
    height: 10px;
    transition: 0.5s;
    transform: rotate(90deg);
    position: absolute;
    right: 12px;
    top: 14px;
}
.icon_select_open{
    width: 10px;
    height: 10px;
    transition: 0.5s;
    transform: rotate(180deg);
    position: absolute;
    right: 12px;
    top: 14px;
}
/* end */


/* textarea */
.div_title_data{
    padding: 20px 0px 6px;
    font-size: 20px;
    color: #606875;
}
.div_show_text{
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    gap: 10px;
    border-radius: 14px;
}
.show_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    gap: 10px;
    padding-bottom: 0px;
    font-weight: 500;
}
.show_textarea{
    width: -webkit-fill-available;
    position: relative;
}
/* end */


/* Name current page */
.div_name_page{
    font-size: 12px;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
}
.div_name_page .icons{
    width: 20px;
    height: 20px;
}
.div_name_page .name_fixed{
    color: #CDD1D6;
}
.div_name_page .name_page{
    color: #2D3C53;
    font-weight: 600;
}
/* end */


/* new data */
.new_block_text {
    background-color: #7c9f06;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 6px;
    padding: 6px 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
    border: transparent;
}
.new_data{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
}
.new_block_text:hover, .new_block_active{
    background-color: #C42B79;
}
.new_project {
    background-color: #7c9f06;
    border: transparent;
}
.new_project:hover {
    background-color: #008562;
}
/* end */


/* show category and projects */
.list_category{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.show_name_category{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    padding: 10px 24px;
    line-height: 15px;
    color: #606875;
    border: 1.5px solid #606875;
    border-radius: 20px;
    cursor: pointer;
}
.show_name_category:hover, .category_active{
    color: #FFFFFF;
    background-color: #606875;
}
.list_portfolio_new_presentation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.list_project{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.div_project_data{
    position: relative;
    width: 238px;
    height: 160px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
}
.project_data_inf{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}
.project_data_client{
    color: #ffffff;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-size: 16px;
    font-weight: 600;
}
.project_data_show_opt{
    color: #ffffff;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    z-index: 4;
}
.project_data_lock_close{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 1;
    background-color: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 2s ease;
}
.project_data_div_img{
    width: 100%;
    height: 100%;
}
.project_data_img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.div_project_data:hover .project_data_lock_close{
    opacity: 0;
    transition: all 0.6s ease;
}
.div_project_data:hover .project_data_inf{
    opacity: 1;
    transition: all 0.6s ease;
    background-color: rgb(0 0 0 / 40%);
    box-shadow: 0px 8px 13px 16px rgb(0 0 0 / 40%);
}
.div_project_data:hover .project_data_client, .div_project_data:hover .project_data_show_opt{
    opacity: 1;
    transition: all 0.6s ease;
    background-color: rgb(45 60 83 / 80%);
}
.div_project_data .select_project_data{
    opacity: 1;
    background-color: rgb(45 60 83 / 80%);
}
.div_project_data .hide_project_data{
    display: none;
}
.project_data_opt_select{
    position: absolute;
    right: 10px;
    top: 8px;
}
.div_project_data .circle_selected{
    width: 24px;
    height: 24px;
    display: block;
}
/* end */


/* switch */
.switch{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
}
.switch .switch_wrapper{
    display: inline-block;
    width: 40px;
    height: 14px;
    position: relative;
}
.switch .switch_wrapper .switch_button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #9d9d9d;
}
.switch .switch_wrapper input{
    opacity: 0;
    width:0px;
    height:0px
}
.switch .switch_wrapper .switch_button::before{
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: -1px;
    top: -4px;
    bottom: 3px;
    border-radius: 50%;
    transition: .4s all ease;
    background-color: #FFFFFF;
    box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper .switch_button:hover:before{
    box-shadow:
        rgb(38 38 38 / 20%) 0px 0px 0px 0px,
        rgb(38 38 38 / 14%) 0px 0px 6px 8px,
        rgb(38 38 38 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper input:checked + .switch_button{
    background-color: #9ae3ac;
}
.switch .switch_wrapper input:checked + .switch_button::before{
    transform:translateX(20px);
    background-color: #34c759;
}
.switch .switch_wrapper input:checked +.switch_button:hover:before {
    box-shadow:
        rgb(79 70 229 / 20%) 0px 0px 0px 0px,
        rgb(79 70 229 / 14%) 0px 0px 6px 8px,
        rgb(79 70 229 / 12%) 0px 1px 3px 0px
}
.new_budget{
    padding: 8px 24px;
    text-transform: capitalize;
}
.show_budget{
    padding: 8px 24px;
    background-color: #49a6ff;
    text-transform: capitalize;
}
/* end */


/* div select */
.div_show_select{
    width: -webkit-fill-available;
    position: relative;
}
.div_show_select_height{
    height: 37px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 10px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
}
.div_show_select_opt_selected{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
.div_show_select_icons{
    border-left: 1px solid #CDD1D6;
    padding-left: 4px;
}
.div_show_select_name_data{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.div_show_select_list_data{
    position: absolute;
    margin-top: 8px;
    border: 2px solid #ebebeb;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 10px;
    border-radius: 6px;
    width: -webkit-fill-available;
    background-color: #ffffff;
    z-index: 10;
    max-height: 220px;
    overflow-y: auto;
}
.div_show_select_name{
    font-size: 12px;
    border-top: 2px solid #CDD1D6;
    width: 100%;
    cursor: pointer;
    min-height: 30px;
    display: flex;
    align-items: center;
}
.div_show_select_new_data{
    text-transform: uppercase;
    color: #E51D4D;
    border-top: none;
    font-weight: 600;
}
.div_show_select_cancel{
    justify-content: end;
    border-top: 2px solid #CDD1D6;
}
.div_category_list{
    width: -webkit-fill-available;
    margin-top: 16px;
}
.div_category_list_no_data{
    color: #CDD1D6;
    font-size: 12px;
    text-align: center;
}
.div_category_list_name{
    flex-grow: 1;
}
.div_category_list_name_category{
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    height: 36px;
}
.div_category_list_icons{
    width: 12px;
    height: 12px;
    background-image: url('circle.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
}
.div_category_list_remuve{
    font-size: 16px;
    text-transform: uppercase;
    color: #E51D4D;
    font-weight: 600;
    padding-right: 10px;
}
.div_category_list_name_category:hover .div_category_list_icons{
    background-image: url('circle_selected.svg');
}
.div_category_list_name_category:hover{
    background-color: rgba(238, 241, 245, 0.5);
    border-radius: 6px;
    padding-left: 6px;
}
/* end */
