.PopUp .popup_category{
    width: 400px;
}
.PopUp .space_top {
    padding-top: 12px;
}
.PopUp .show_input{
    display: flex;
    gap: 10px;
    width: -webkit-fill-available;
}
.PopUp .button_category{
    width: -webkit-fill-available;
}
.PopUp .type_category{
    width: 100px;
}
.PopUp .div_type{
    width: auto;
}
