.AboutChroma{
    width: 100%;
    height: auto;
}
.AboutChroma .show_textarea{
    width: -webkit-fill-available;
}
.AboutChroma .div_input{
    flex-grow: 1;
}
.AboutChroma .width_input{
    min-width: 230px;
}
