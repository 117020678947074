.PopUp .popup_signature{
    width: 500px;
}
.PopUp .div_img{
    width: 100%;
    height: auto;
    max-height: 160px;
}
.PopUp .show_div_img{
    width: 100%;
    height: auto;
    max-height: 160px;
}
