.PopUp .new_client{
    width: 540px;
}
.PopUp .show_input{
    display: flex;
    gap: 10px;
    width: -webkit-fill-available;
}
.PopUp .div_input{
    width: auto;
}
.PopUp .space_client{
    width: -webkit-fill-available !important;
    margin-top: 0;
}
.PopUp .space_client_top{
    padding-top: 0;
}
.PopUp .cnpj{
    width: 140px;
    text-align: center;
}
