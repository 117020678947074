.Presentation{
    width: 100%;
    height: auto;
}
.Presentation .div_search{
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Presentation .div_qtd_presentation{
    width: -webkit-fill-available;
    text-align: end;
}
.Presentation .icon_site{
    width: 24px;
    height: 24px;
}
.Presentation .status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 22px;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    padding: 0px 14px;
    gap: 10px;
    height: 26px;
    cursor: pointer;
}
.Presentation .icon_status{
    display: block;
    width: 16px;
    height: 16px;
}
.Presentation .all_status{
    color: rgb(255 255 255);
    background-color: rgb(50 77 107);
    justify-content: center;
    padding: 0;
    width: 110px;
}
.Presentation .no_presentation{
    text-align: center;
}

.Presentation .list_presentation{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Presentation .show_presentation{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ededed;
    height: 64px;
    padding: 0px 20px;
    border-radius: 6px;
    cursor: pointer;
}
.Presentation .name_presentation{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.Presentation .title_presentation, .Presentation .client_presentation{
    color: #000000;
    font-size: 16px;
    font-family: 'Inter';
}
.Presentation .client_presentation{
    color: #868686;
    font-size: 14px;
}

.Presentation .budget {
    padding: 4px 12px;
    font-size: 14px;
    text-transform: capitalize;
    width: 120px;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
}
.Presentation .new_budget{
    background-color: #7c9f06;
    cursor: pointer;
}
.Presentation .show_budget{
    background-color: #49a6ff;
    cursor: pointer;
}
