.PopUp .pop_new_project{
    width: 80%;
}
.PopUp .button_project{
    width: -webkit-fill-available;
}
.PopUp .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .textarea_presentation{
    resize: none;
    min-height: 40px;
}
