.Services{
    width: 100%;
    height: auto;
}
.Services .list_services{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Services .no_services{
    text-align: center;
}
.Services .search_status{
    width: 200px;
}
.Services .search_status_{
    width: 216px;
}
.Services .show_data_fixed{
    margin-bottom: 16px;
}
