.ShowDetails{
    width: 100%;
    height: auto;
    padding-bottom: 70px;
}
.ShowDetails .topic{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    color: #868686;
}
.ShowDetails .list_presentation{
    width: -webkit-fill-available;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.ShowDetails .list_status{
    position: relative;
}
.ShowDetails .status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    width: 116px;
    padding: 0px 14px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 36px;
}
.ShowDetails .opt_status{
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 20;
    width: 144px;
    margin-top: 4px;
    background-color: #FFFFFF;
    transition: opacity 247ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 165ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    overflow: hidden auto;
    border-radius: 6px;
    max-height: 200px;
    cursor: pointer;
}
.ShowDetails .sub_{
    border-radius: 0;
    color: #6a85ae;
}
.ShowDetails .sub_:hover{
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.ShowDetails .title_div{
    font-weight: 600;
    font-size: 16px;
    color: inherit;
}
.ShowDetails .icon_status{
    display: block;
    width: 16px;
    height: 16px;
}
.ShowDetails .icon_folder{
    display: block;
    width: 40px;
    height: 40px;
}
.ShowDetails .subtitle{
    font-family: 'inter';
    font-size: 24px;
    line-height: 29px;
    color: #868686;
}
.ShowDetails .text{
    margin-top: 30px;
}
.ShowDetails .icon_title_project{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: -webkit-fill-available;
}
.ShowDetails .list_project_add{
    width: 180px;
}


.ShowDetails .list_portfolio{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    overflow: hidden;
    position: relative;
    width: -webkit-fill-available;
}
.ShowDetails .list_portfolio .show_portfolio{
    width: 180px;
    height: 300px;
    position: relative;
    transition: all 0.6s ease;
    overflow: hidden;
    border-radius: 6px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.ShowDetails .list_portfolio .title, .Portfolio .list_portfolio .subtitle{
    height: 44px;
    position: absolute;
    width: -webkit-fill-available;
    color: #F3F5F9;
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.6s ease;
    justify-content: space-between;
    padding: 0px 8px;
    z-index: 1;
}
.ShowDetails .list_portfolio .subtitle{
    height: 44px;
    position: absolute;
    width: -webkit-fill-available;
    color: #F3F5F9;
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.6s ease;
    justify-content: space-between;
    padding: 0px 8px;
    z-index: 1;
}
.ShowDetails .list_portfolio .show_portfolio:hover .title, .ShowDetails .list_portfolio .show_portfolio:hover .subtitle{
    background-color: rgb(0 0 0 / 87%);
    box-shadow: 0px 8px 13px 16px rgb(0 0 0 / 87%);
}
.ShowDetails .list_portfolio .name_client {
    opacity: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    width: -webkit-fill-available;
    transition: all 0.6s ease;
}
.ShowDetails .list_portfolio .show_portfolio:hover .name_client{
    opacity: 1;
}
.ShowDetails .list_portfolio .icons_project{
    opacity: 0;
    transition: all 0.6s ease;
    width: auto;
    height: auto;
}
.ShowDetails .list_portfolio .show_portfolio:hover .icons_project{
    opacity: 1;
}
.ShowDetails .list_portfolio .show_example{
    height: -webkit-fill-available;
    left: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.ShowDetails .list_portfolio .show_img{
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

.ShowDetails .div_input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.ShowDetails .div_restricted{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-weight: 600;
}
.ShowDetails .name_restricted{
    line-height: 15px;
    font-size: 12px;
}
.ShowDetails .list_opt_alt_page{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

/* switch */
.ShowDetails .switch{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ShowDetails .switch .switch_wrapper{
    display: inline-block;
    width: 40px;
    height: 14px;
    position: relative;
}
.ShowDetails .switch .switch_wrapper .switch_button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #9d9d9d;
}
.ShowDetails .switch .switch_wrapper input{
    opacity: 0;
    width:0px;
    height:0px
}
.ShowDetails .switch .switch_wrapper .switch_button::before{
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: -1px;
    top: -4px;
    bottom: 3px;
    border-radius: 50%;
    transition: .4s all ease;
    background-color: #FFFFFF;
    box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.ShowDetails .switch .switch_wrapper .switch_button:hover:before{
    box-shadow:
        rgb(38 38 38 / 20%) 0px 0px 0px 0px,
        rgb(38 38 38 / 14%) 0px 0px 6px 8px,
        rgb(38 38 38 / 12%) 0px 1px 3px 0px;
}
.ShowDetails .switch .switch_wrapper input:checked + .switch_button{
    background-color: rgb(165 161 240);
}
.ShowDetails .switch .switch_wrapper input:checked + .switch_button::before{
    transform:translateX(20px);
    background-color: rgb(79, 70, 229);
}
.ShowDetails .switch .switch_wrapper input:checked +.switch_button:hover:before {
    box-shadow:
        rgb(79 70 229 / 20%) 0px 0px 0px 0px,
        rgb(79 70 229 / 14%) 0px 0px 6px 8px,
        rgb(79 70 229 / 12%) 0px 1px 3px 0px
}
.ShowDetails .show_category{
    width: -webkit-fill-available;
}
.ShowDetails .new_budget{
    padding: 6px 14px;
    text-transform: capitalize;
}
.ShowDetails .space_data{
    min-width: 200px;
}
.ShowDetails .show_budget{
    padding: 6px 14px;
    background-color: #49a6ff;
    text-transform: capitalize;
}
/* end */

.ShowDetails .div_data_about{
    margin-bottom: 16px;
}
.ShowDetails .show_data_about{
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #111827;
    border-radius: 10px;
}
.ShowDetails .div_about_img{
    width: 200px;
    height: 140px;
    position: relative;
}
.ShowDetails .about_img{
    width: 200px;
    height: 140px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.ShowDetails .div_about_title{
    width: -webkit-fill-available;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111827;
    color: #ffffff;
    font-size: 12px;
    position: relative;
    cursor: pointer;
}
.ShowDetails .title_about{
    width: -webkit-fill-available;
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}
.ShowDetails .about_text{
    border-top: 1px solid #c1c1c1;
    height: auto;
    padding: 10px;
    text-align: justify;
}
.ShowDetails .about_link{
    position: absolute;
    right: 5px;
    bottom: 0px;
}
.ShowDetails .about_radius{
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.ShowDetails .div_text{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
}

.ShowDetails .lock_close{
    position: absolute;
    z-index: 1;
    background-color: rgb(0 0 0 / 30%);
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
}
.ShowDetails .show_portfolio:hover .lock_close{
    display: none;
}
.ShowDetails .lock{
    width: 50px;
    height: 50px;
}

.ShowDetails .list_link{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.ShowDetails .icon_select_close{
    width: 10px;
    height: 10px;
    transition: 0.5s;
    transform: rotate(90deg);
    position: absolute;
    right: 12px;
}
.ShowDetails .icon_select_open{
    width: 10px;
    height: 10px;
    transition: 0.5s;
    transform: rotate(180deg);
    position: absolute;
    right: 12px;
}

.ShowDetails .text_desc{
    position: relative;
    background-color: #ffffff;
    z-index: 111;
    margin-top: 16px;
    display: flex;
}
.ShowDetails .textarea_presentation{
    resize: none;
}
.ShowDetails .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -10px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.ShowDetails .add_new_project{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ShowDetails .div_show_text{
    align-items: flex-start;
}
