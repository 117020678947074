.Report{
    width: 100%;
    height: auto;
}
.Report .list_data_year{
    width: auto;
    height: auto;
}
.Report .typeData{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.Report .div_data{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    width: 200px;
    min-width: 200px;
    height: 120px;
    cursor: pointer;
    margin-top: 20px;
}
.Report .div_data:hover, .Report .div_active{
    transform: scale(0.90);
}
.Report .qtd_{
    font-size: 40px;
    background-color: #2d3c53;
    width: 120px;
    height: 120px;
    position: absolute;
    top: -20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}
.Report .title_div{
    font-size: 16px;
}
.Report .list_grafics{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

/* detalhes report */
.return_opt{
    cursor: pointer;
    width: max-content;
}
.icon_next {
    transform: rotate(180deg);
}
/* end */

/* table */
.list_data_report .title_table{
    margin-top: 20px;
    padding-bottom: 10px;
}
.list_data_report .show_status{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: center;
}
.list_data_report .show_status_qtd{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.list_data_report .show_table{
    overflow-x: auto;
}
/* end */
