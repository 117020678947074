.PopUp .new_contact{
    width: 540px;
}
.PopUp .show_input{
    display: flex;
    gap: 10px;
    width: -webkit-fill-available;
}
.PopUp .div_input{
    width: auto;
}
.PopUp .space_contact{
    width: -webkit-fill-available;
}
.PopUp .phone{
    width: 130px;
    text-align: center;
}
