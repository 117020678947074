.AccessDash{
    padding-bottom: 70px;
}
.AccessDash .icons{
    cursor: pointer;
}
.AccessDash{
    width: 100%;
    height: auto;
}
.AccessDash .icon_user{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
}
.AccessDash .icon_site{
    width: 24px;
    height: 24px;
}
