.Portfolio_Project{
    width: 100%;
    height: auto;
}
.Portfolio_Project .div_data_project{
    flex-wrap: wrap;
}
.Portfolio_Project .data_project{
    flex-grow: 1;
    overflow-x: auto;
}
.Portfolio_Project .data_fixed{
    width: 280px;
    min-width: 280px;
    max-width: 280px;
}
.Portfolio_Project .div_input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Portfolio_Project .year{
    width: 36px;
    text-align: center;
}
.Portfolio_Project .div_show_category{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    gap: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c1c1c1;
}
.Portfolio_Project .show_category{
    padding: 10px;
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    gap: 10px;
    border-radius: 6px;
}
.Portfolio_Project .add_category{
    background: #c5d2e8;
    border-radius: 4px;
    color: #2d3c53;
    cursor: pointer;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 24px;
    position: relative;
    text-transform: uppercase;
}
.Portfolio_Project .select_category{
    flex-grow: 1;
}
.Portfolio_Project .space_data{
    min-width: 200px;
}
.Portfolio_Project .span_name_select{
    background-color: #ffffff;
}
.Portfolio_Project .space_select{
    width: 100px;
    padding: 9px;
}
.Portfolio_Project .name_img{
    width: 160px;
}
.Portfolio_Project .select_new_category{
    flex-grow: 1;
    display: flex;
}
.Portfolio_Project .icons_project{
    width: auto;
    height: 30px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    position: relative;
    top: 4px;
    cursor: pointer;
}
.Portfolio_Project .preview_eye{
    display: flex;
}
.Portfolio_Project .preview_project{
    display: none;
}
.Portfolio_Project .icons_project:hover .preview_project{
    display: block;
}
.Portfolio_Project .div_alt_data_contents{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 10px;
}
.Portfolio_Project .registered_category{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    gap: 10px;
}
.Portfolio_Project .category_detalis{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #ffffff;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
}
.Portfolio_Project .div_icons{
    display: flex;
    padding-left: 0px;
}
.Portfolio_Project .no_data{
    margin-bottom: 10px;
}
.Portfolio_Project .project_no_data{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #6a85ae;
    border-radius: 8px;
}
.Portfolio_Project .add_data{
    display: flex;
}

.Portfolio_Project .type_align{
    position: relative;
}
.Portfolio_Project .show_contents_add{
    background-color: #ededed;
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
    width: -webkit-fill-available;
    overflow-x: auto;
}
.Portfolio_Project .opt_align{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.Portfolio_Project .list_icons{
    width: -webkit-fill-available;
    display: flex;
}
.Portfolio_Project .remuve_content{
    display: flex;
}
.Portfolio_Project .button_align{
    font-family: 'Poppins';
    font-weight: 500;
    background: #C5D2E8;
    border-radius: 4px;
    color: #2D3C53;
    font-size: 12px;
    padding: 6px 24px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
}
.Portfolio_Project .button_active{
    background-color: #2d3c53;
    color: #FFFFFF;
}
.Portfolio_Project .list_align{
    width: 94px;
    height: 94px;
    background-color: #EEF1F5;
    position: absolute;
    border: 2px solid #CDD1D6;
    border-radius: 4px;
    z-index: 2;
    top: 0px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Portfolio_Project .list_active{
    display: flex;
}
.Portfolio_Project .img_0 {
    width: 20px;
    height: 20px;
    background-image: url('ellipseCenter.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}
.Portfolio_Project .img_1_active{
    background-image: url('textLeft.svg');
}
.Portfolio_Project .img_1:hover{
    background-image: url('textLeft.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}
.Portfolio_Project .img_2_active{
    background-image: url('textCenter.svg');
}
.Portfolio_Project .img_2:hover{
    background-image: url('textCenter.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}
.Portfolio_Project .img_3_active{
    background-image: url('textRight.svg');
}
.Portfolio_Project .img_3:hover{
    background-image: url('textRight.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}

.Portfolio_Project .list_content{
    padding: 10px;
    width: -webkit-fill-available;
}
.Portfolio_Project .list_opt{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Portfolio_Project .show_opt{
    display: flex;
    width: 100px;
    height: 72px;
}
.Portfolio_Project .opt_icon {
    width: 100%;
    height: 100%;
}
.Portfolio_Project .div_show_or_new_data .div_data_project{
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    padding-bottom: 10px;
    overflow-x: auto;
    align-items: flex-start;
}
.Portfolio_Project .div_data_project .add_project{
    height: auto;
    width: -webkit-fill-available;
}
.Portfolio_Project .show_data_project{
    overflow-x: initial;
    width: 100%;
    max-width: 100%;
    min-height: 148px !important;
    justify-content: flex-start;
}
.Portfolio_Project .div_opt_add{
    background-color: #6A85AE;
    color: #ffffff;
    border-radius: 6px;
    width: 80px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    min-width: 80px;
}
.Portfolio_Project .no_type{
    text-align: center;
    height: 60px;
    min-height: 60px;
    color: #b1afaf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #6c757d;
}
.Portfolio_Project .align_content{
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}
.Portfolio_Project .sublist_active{
    display: flex;
    top: 30px;
}
.Portfolio_Project .space_new_data{
    margin-bottom: 20px;
}
.Portfolio_Project .DefaultDiv{
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}
.Portfolio_Project .div_data_input{
    position: relative;
}
.Portfolio_Project .cover{
    text-align: center;
    cursor: pointer;
}
.Portfolio_Project .cover_title{
    text-align: center;
    background-color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Portfolio_Project .cover_active{
    background-color: #3452fe;
    color: #fff;
}
.Portfolio_Project .img_cover{
    width: 110px;
    height: auto;
}
.Portfolio_Project .div_add_type{
    display: flex;
    width: -webkit-fill-available;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.Portfolio_Project .list_opt_alt_page{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.Portfolio_Project .show_textarea{
    width: -webkit-fill-available;
}
.Portfolio_Project .back_to_top {
    position: fixed;
    bottom: 30px;
    left: 30px;
    background-color: #324d6b;
    z-index: 999;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
}
.Portfolio_Project .icons_return_top{
    transform: rotate(90deg);
}

.Portfolio_Project .title_div{
    font-weight: 600;
    font-size: 16px;
    color: inherit;
}
.Portfolio_Project .space_top{
    margin-top: 10px;
}

.Portfolio_Project .select_type_category{
    width: 130px;
}
.Portfolio_Project .input_category{
    width: 180px;
}
.Portfolio_Project .opt_category{
    width: 300px;
}
.Portfolio_Project .space_services{
    overflow-x: auto;
    padding-bottom: 14px;
}

.Portfolio_Project .show_data_add{
    padding: 10px;
}
.Portfolio_Project .show_title{
    padding-bottom: 4px;
}
.Portfolio_Project .show_data_register{
    flex-direction: row;
    align-items: flex-start;
    overflow-x: hidden;
}
.Portfolio_Project .div_restricted {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    color: #f00000;
    font-weight: 600;
    width: -webkit-fill-available;
    margin-bottom: 10px;
}
.Portfolio_Project .div_restricted_lock{
    width: 24px;
}
.Portfolio_Project .div_restricted_title{
    flex-grow: 1;
    color: #606875;
}
.Portfolio_Project .div_restricted_switch{
    width: 40px;
}
.Portfolio_Project .div_category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    margin-top: 10px;
    position: relative;
}
.Portfolio_Project .div_category_title{
    font-weight: 600;
    color: #606875;
}
.Portfolio_Project .div_category_search{
    margin-top: 10px;
}
.Portfolio_Project .search{
    width: 100%;
}

.Portfolio_Project label{
    position: relative;
}
.Portfolio_Project .div_page_cover{
    width: -webkit-fill-available;
    position: relative;
}
.Portfolio_Project .div_page_cover_edit{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgb(0 0 0 / 50%);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.Portfolio_Project .close_file_edit{
    display: none;
}
.Portfolio_Project .div_cover{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 2px dashed #cdd1d6;
    border-radius: 6px;
    padding: 20px 0px;
    margin-top: 20px;
    width: -webkit-fill-available;
    max-height: 300px;
}
.Portfolio_Project .div_cover_img{
    padding: 0;
    border: none;
}
.Portfolio_Project .close_file{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    opacity: 0;
    cursor: pointer;
}
.Portfolio_Project .show_img_cover{
    width: auto;
    height: 100%;
    border-radius: 14px;
    max-width: 100%;
    max-height: 300px;
}
.Portfolio_Project .div_cover_icons .icons{
    display: flex;
    width: 80px;
    height: 80px;
}
.Portfolio_Project .div_cover_title{
    font-weight: 400;
}
.Portfolio_Project .div_cover_explication{
    font-size: 12px;
    font-weight: 600;
}
.Portfolio_Project .div_cover_color{
    color: rgba(7, 196, 214, 1);
}
.Portfolio_Project .div_cover_desc{
    font-size: 10px;
    font-weight: 400;
}

.Portfolio_Project .div_links_space_top {
    margin-top: 20px;
}
.Portfolio_Project .div_links{
    display: flex;
    flex-direction: row;
    gap: 6px;
}
.Portfolio_Project .explication{
    font-size: 12px;
    color: #CDD1D6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Portfolio_Project .div_new_data_project{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #cdd1d6;
    border-radius: 8px;
}
.Portfolio_Project .div_data_topic_project{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Portfolio_Project .div_align{
    color: #E51D4D;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
}
.Portfolio_Project .div_type_align{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.Portfolio_Project .align{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 2px;
}
.Portfolio_Project .div_switch{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-left: 1px solid #cdd1d6;
    padding-left: 6px;
    margin-left: 6px;
}
.Portfolio_Project .align_type_contents{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
