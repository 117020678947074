.NewServices .cash{
    width: 80px;
}
.NewServices .div_select_width{
    width: -webkit-fill-available;
}
.NewServices .add_new_data{
    padding: 10px;
    background-color: #ffffff;
    border-radius: 6px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.NewServices .div_title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.NewServices .type_text{
    width: 150px;
}
.NewServices .space_top {
    padding-top: 12px;
}
