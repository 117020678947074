.Portfolio{
    width: 100%;
    height: auto;
}

/* create new presentation */
.Portfolio .new_presentation{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: rgba(7, 196, 214, 0.1);
    border-radius: 4px;
}
.Portfolio .presentation_title{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #07C4D6;
}
.Portfolio .save_presentation{
    text-align: left;
    width: -webkit-fill-available;
}
.Portfolio .summary {
    font-family: 'Inter';
    font-weight: 600;
    color: #07C4D6;
    border-bottom: 2px solid #07C4D6;
    text-align: left;
    padding-bottom: 6px;
}
.Portfolio .list_project_add {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
.Portfolio .show_icon_presentation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 6px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 2px solid #ebebeb;
}
.Portfolio .cancel_presentation{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #E51D4D;
    cursor: pointer;
    text-align: center;
}
/* end */
