.AccessDash_Details {
    padding-bottom: 70px;
}
.AccessDash_Details .data_select{
    width: 140px;
}
.AccessDash_Details .pass, .AccessDash_Details .dash_user_name {
    width: 120px;
}
.AccessDash_Details .dash_user_email {
    width: 220px;
}
.AccessDash_Details .space_top{
    padding-top: 12px;
}
.AccessDash_Details .space_page{
    flex-grow: 1;
    width: -webkit-fill-available;
}
