.NewClient .space_top{
    padding-top: 12px;
}
.NewClient .cnpj{
    width: 140px;
    text-align: center;
}
.NewClient .customer_since{
    width: 120px;
    text-align: center;
}
.NewClient .show_contact{
    padding: 10px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    width: -webkit-fill-available;
}
.NewClient .phone{
    width: 130px;
    text-align: center;
}
.NewClient .show_contact:nth-last-child(-n + 1) {
    margin-bottom: 0px;
}
.NewClient .show_data_register{
    gap: 10px;
}
