.NewBudget .space_top{
    padding-top: 12px;
}
.NewBudget .space_data{
    min-width: 200px;
}
.NewBudget .list_services{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #6a85ae;
    border-radius: 8px;
}
.NewBudget .show_services{
    margin-bottom: 10px;
    padding: 6px;
    background-color: #ffffff;
    border-radius: 8px;
}
.NewBudget .show_services_registered{
    margin-bottom: 10px;
    padding: 19px 10px 10px;
    background-color: #ffffff;
    border-radius: 8px;
}
.NewBudget .data_client{
    padding: 19px 10px 10px;
    background-color: #ffffff;
    border-radius: 8px;
    width: -webkit-fill-available;
}

.NewBudget .list_status{
    position: relative;
}
.NewBudget .status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    width: 140px;
    padding: 0px 14px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 36px;
}
.NewBudget .opt_status{
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 20;
    width: 168px;
    margin-top: 4px;
    background-color: #FFFFFF;
    transition: opacity 247ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 165ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    overflow: hidden auto;
    border-radius: 6px;
    max-height: 200px;
    cursor: pointer;
    top: 48px;
}
.NewBudget .sub_{
    border-radius: 0;
    color: #6a85ae;
}
.NewBudget .sub_:hover{
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.NewBudget .icon_status{
    display: block;
    width: 16px;
    height: 16px;
}
.NewBudget .date_space{
    width: 150px;
}
.NewBudget .cash {
    width: 80px;
}
.NewBudget .data_services{
    border-top: 1px solid #c1c1c1;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: -webkit-fill-available;
}
.NewBudget ._registered{
    border-top: none;
    padding-top: 0px;
    margin-top: 0px;
}
.NewBudget .select_contact{
    width: 400px;
}
.NewBudget .select_signature{
    width: 200px;
}
.NewBudget .list_opt_alt_page{
    display: flex;
    gap: 10px;
}
.NewBudget .div_icons{
    align-items: center
}
.NewBudget .tax{
    width: 50px;
    text-align: center;
}
.NewBudget .select_percentage{
    width: 110px;
}
.NewBudget .open_or_close_text{
    /* position: absolute;
    right: 8px;
    top: 8px; */
    cursor: pointer;
    z-index: 10;
}


                .div_service{
                    width: 100%;
                    background-color: rgb(98 98 98);
                    color: #ffffff;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    padding: 0px;
                    position:relative;
                    height: 33px;
                }
                .list_service{
                    background-color: #ffffff;
                    color: rgb(98 98 98);
                }
                .div_service .border_left{
                    border-left: 1px solid #c9c9c9;
                }
                .div_service .border_right{
                    border-right: 1px solid #c9c9c9;
                }
                .div_service .border_bottom{
                    border-right: 1px solid #c9c9c9;
                    border-bottom: 1px solid #c9c9c9;
                }
                .div_service .serv_numb{
                    width: 20px;
                    text-align: center;
                }
                .div_service .serv_item{
                    width: 525px;
                    flex-grow: 1;
                    left: 33px;
                }
                .div_service .serv_qtd{
                    width: 56px;
                    right: 96px;
                }
                .div_service .serv_cash{
                    width: 84px;
                    text-align: center;
                    right: 0;
                }
                .div_service .title_topic{
                    font-weight: 700;
                    padding: 6px;
                    position: absolute;
                }
