.Budget{
    width: 100%;
    height: auto;
}
.Budget .list_budget{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Budget .no_budget{
    text-align: center;
}
.Budget .search_status{
    width: 140px;
}
.Budget .div_search_status{
    width: 156px;
}
.Budget .list_opt{
    justify-content: flex-end;
}
