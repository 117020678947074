.PopUpFile {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 80%);
}
.PopUpFile .all {
    width: 760px;
    height: auto;
    display: flex;
    text-align: center;
    border-radius: 8px;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #F4F6F9;
    overflow-y: hidden;
    position: relative;
}
.PopUpFile .type_div {
    border-bottom: 1px solid #6c757d;
}
.PopUpFile .popup_title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}
.PopUpFile .popup_close {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}
.PopUpFile .div_data {
    display: flex;
    text-align: left;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUpFile .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    margin-top: 20px;
}
.PopUpFile .div_contents {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 16px;
}
.PopUpFile .button {
    padding: 0px 20px 20px;
}
.PopUpFile .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUpFile .div_input {
    margin: 16px 0px;
}
.PopUpFile .show_img{
    max-width: 100%;
}
.PopUpFile .div_video{
    width: -webkit-fill-available;
    height: 400px;
}
